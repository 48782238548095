<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn :to="{name: 'celebrations'}" color="primary" outlined icon><v-icon v-html="icons.mdiArrowLeft" /></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="text--primary">
            <v-form>
              <v-row>
                <v-col cols="12">
                  <h2>Celebration Details</h2>
                </v-col>
                <v-col cols="12" v-if="$store.state.me && $store.state.me.role === 'admin'" :sm="$store.state.me && $store.state.me.role === 'admin' ? 6 : 4" :md="$store.state.me && $store.state.me.role === 'admin' ? 3 : 4">
                  <v-select
                    outlined
                    dense
                    label="Temple"
                    item-text="name"
                    item-value="id"
                    :items="templeOptions"
                    v-model="form.temple_id"
                    :error-messages="errors.temple_id"
                    @change="removeFromLocations"
                  />
                </v-col>
                <v-col cols="12" :sm="$store.state.me && $store.state.me.role === 'admin' ? 6 : 4" :md="$store.state.me && $store.state.me.role === 'admin' ? 3 : 4">
                  <v-select
                    outlined
                    dense
                    label="Festival"
                    item-text="name"
                    item-value="id"
                    :items="festivalOptions"
                    v-model="form.festival_id"
                    :error-messages="errors.festival_id"
                  />
                </v-col>
                <v-col cols="12" :sm="$store.state.me && $store.state.me.role === 'admin' ? 6 : 4" :md="$store.state.me && $store.state.me.role === 'admin' ? 3 : 4">
                  <v-text-field
                    outlined
                    dense
                    label="Name"
                    placeholder="Name"
                    v-model="form.name"
                    :error-messages="errors.name"
                  />
                </v-col>
                <v-col cols="12" :sm="$store.state.me && $store.state.me.role === 'admin' ? 6 : 4" :md="$store.state.me && $store.state.me.role === 'admin' ? 3 : 4">
                  <v-text-field
                    outlined
                    dense
                    prefix="₹"
                    label="Target"
                    placeholder="Target"
                    type="number"
                    v-model="form.target"
                    :error-messages="errors.target"
                  />
                </v-col>
                <v-col cols="12">
                  <v-divider/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h2>Donation Categories</h2>
                </v-col>
                <v-col v-for="(donationTypeOption, key) in donationTypeOptions" :key="key" cols="12">
                  <v-row>
                    <v-col cols="12" sm="4" md="2">
                      <v-checkbox
                        class="shrink mt-0"
                        :label="donationTypeOption.name"
                        :value="donationTypeOption.id"
                        v-model="selectedDonationTypes"
                      />
                    </v-col>
                    <template v-if="form.donation_types.some(item => item.id === donationTypeOption.id)">
                      <v-col cols="12" sm="4" md="2">
                        <v-text-field
                          outlined
                          dense
                          type="number"
                          prefix="₹"
                          label="Per Unit"
                          placeholder="Per Unit"
                          v-model="form.donation_types[form.donation_types.findIndex(item => item.id === donationTypeOption.id)].per_unit"
                        />
                      </v-col>
                      <v-col cols="12" sm="4" md="2">
                        <v-text-field
                          v-if="form.donation_types[form.donation_types.findIndex(item => item.id === donationTypeOption.id)].per_unit"
                          outlined
                          dense
                          label="Requied Units"
                          placeholder="Requied Units"
                          type="number"
                          v-model="form.donation_types[form.donation_types.findIndex(item => item.id === donationTypeOption.id)].target_unit"
                        />
                        <v-text-field
                          v-else
                          outlined
                          dense
                          prefix="₹"
                          label="Target"
                          placeholder="Target"
                          type="number"
                          v-model="form.donation_types[form.donation_types.findIndex(item => item.id === donationTypeOption.id)].target_amount"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-select
                          label="Accept At"
                          outlined
                          dense
                          chips
                          deletable-chips
                          clearable
                          multiple
                          item-text="name"
                          item-value="id"
                          :items="templeOptions.filter(item => item.id !== form.temple_id)"
                          v-model="form.donation_types[form.donation_types.findIndex(item => item.id === donationTypeOption.id)].locations"
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
            <v-divider/>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn type="reset" class="mx-2" outlined><router-link :to="{name: 'celebrations'}">Cancel</router-link></v-btn>
            <v-btn color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar.active = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import { mdiArrowLeft } from '@mdi/js'

let celebrationSchema = {
  temple_id: null,
  festival_id: null,
  name: null,
  target: null,
  donation_types: [],
};

export default {
  data() {
    return {
      form: {
        ...{id: null},
        ...celebrationSchema
      },
      errors: {...celebrationSchema},
      donationTypeOptions: [],
      templeOptions: [],
      festivalOptions: [],
      snackbar: {
        active: false,
        message: null,
        timeout: -1,
        color: null
      },
      icons: {
        mdiArrowLeft,
      },
    }
  },
  computed: {
    selectedDonationTypes: {
      get() {

        return this.form.donation_types.map(item => item.id);
      },
      set(value) {

        this.form.donation_types = this.form.donation_types.filter(item => value.includes(item.id));
        value.forEach(selectedDonationTypeID => {
          if (!this.form.donation_types.some(item => item.id === selectedDonationTypeID)) {
            this.form.donation_types.push({id: selectedDonationTypeID, locations: [], per_unit: this.donationTypeOptions.find(item => item.id === selectedDonationTypeID).per_unit, target_unit: null, target_amount: null});
          }
        });
      }
    }
  },
  created() {

    axiosBackend.get(`/options/donation-types`).then(async (response) => {

      this.donationTypeOptions = response.data.donation_types;

      axiosBackend.get(`/options/festivals`).then(async (response) => {

        this.festivalOptions = response.data.festivals;

        axiosBackend.get(`/options/temples`).then(async (response) => {

          this.templeOptions = response.data.temples;

          if (this.$route.name === 'edit-celebration') {

            axiosBackend.get(`/celebrations/${this.$route.params.id}`).then(async (response) => {

              this.form = response.data.celebration;
              this.form.donation_types = this.form.donation_types.map(donationType => {

                donationType = (({id, per_unit, target_unit, target_amount, locations}) => ({id, per_unit, target_unit, target_amount, locations}))(donationType);

                donationType.locations = donationType.locations.map(donationTypeLocation => {
                  return donationTypeLocation.id;
                });
                return donationType;
              });
            }).catch(error => {

              if (error.response.status === 403 || error.response.status === 404) this.$router.push({name: 'celebrations'});
            });
          }
        });
      });
    });
  },
  methods: {
    save() {

      this.loading = true;
      let apiURL = '/celebrations';
      if (this.form.id) apiURL += '/' + this.form.id;

      axiosBackend.post(apiURL, this.form)
        .then((response) => {

          this.$router.push({name: 'celebrations'});
        })
        .catch((error) => {

          if (error.response.status === 422) {

            this.errors = error.response.data.errors;
          }
          this.snackbar = {
            active: true,
            color: 'error',
            timeout: 5000,
            message: error.response.data.message,
          };
        });
    },
    removeFromLocations()
    {

      this.form.donation_types = this.form.donation_types.map(donationType => {

        donationType.locations = donationType.locations.filter(donationTypeLocation => {
          return donationTypeLocation !== this.form.temple_id;
        });
        return donationType;
      });
    }
  }
}
</script>
